import React from "react";
import ContactForm from "../components/contact-form/ContactForm";

const Contact = () =>{ 
    return (
        <>
            <section id="down" className="dark-bg-1 flex-min-height-box">
                <div className="flex-min-height-inner">
                    <div className="container top-padding-120 bottom-padding-60">
                        <div data-animation-container>
                            <h2 className="large-title">
                                <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Contact us">Contact us</span>
                            </h2>
                            <p data-animation-child className="p-style-small text-color-5 fade-anim-box tr-delay03" data-animation="fade-anim" style={{ maxWidth: 1000, }}>
                                Do you want to work with or for us, or do you just have a question or some feedback? Either way, there are several ways to get in touch. Use one of these methods or the form below!
                            </p>
                        </div>
						
                        <div className="flex-container top-padding-90 contact-box">
                            <div className="contact-box-column bottom-padding-60">
                                <div data-animation-container className="content-left-right-margin-10">
                                    <p data-animation-child className="small-title-oswald red-color overlay-anim-box2" data-animation="overlay-anim2">Visit us (Mon-Fri 9-17)</p>
                                    <h6 className="title-style text-color-4">
                                        <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01" data-animation="overlay-anim2">Zilverparkkade 64</span><br />
                                        <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay02" data-animation="overlay-anim2">8232 W Lelystad</span><br />
                                        <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay03" data-animation="overlay-anim2">The Netherlands</span>
                                    </h6>
                                    <div data-animation-child className="flip-btn-box fade-anim-box tr-delay04" data-animation="fade-anim">
                                        <a href="https://goo.gl/maps/CqkAzRxVZWEQYRyz7" target="_blank" className="flip-btn pointer-large" data-text="open in google maps" rel="noreferrer">open in google maps</a>
                                    </div>
                                </div>
                            </div>

                            <div className="contact-box-column bottom-padding-60">
                                <div data-animation-container className="content-right-margin-20">
                                    <p data-animation-child className="small-title-oswald red-color overlay-anim-box2" data-animation="overlay-anim2">Write us</p>
                                    <p className="title-style text-color-4">
                                        <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1" data-animation="overlay-anim2">Use the</span><br />
                                        <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01" data-animation="overlay-anim2">contact form</span><br />
                                    </p>
                                    <div style={{ position: "relative", height: 25, }}>
                                        <a href="#contact" className="scroll-btn pointer-large">
                                            <div className="scroll-btn-flip-box">
                                                <span className="scroll-btn-flip" data-text="Scroll Down">Scroll Down</span>
                                            </div>
                                            <div className="scroll-arrow-box">
                                                <span className="scroll-arrow" />
                                            </div>
                                        </a>
                                    </div>
                                    {/* <p className="title-style text-color-4">
                                        <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01" data-animation="overlay-anim2">Accounts</span><br />
                                        <h6 data-animation-child className="flip-btn-box fade-anim-box tr-delay04" data-animation="fade-anim">
                                            <a href="#" className="flip-btn pointer-large" data-text="email@xen_agency.com">email@xen_agency.com</a>
                                        </h6>

                                        <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01" data-animation="overlay-anim2">Careers</span><br />
                                        <h6 data-animation-child className="flip-btn-box fade-anim-box tr-delay04" data-animation="fade-anim">
                                            <a href="#" className="flip-btn pointer-large" data-text="email@xen_agency.com">email@xen_agency.com</a>
                                        </h6>

                                        <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01" data-animation="overlay-anim2">Info</span><br />
                                        <h6 data-animation-child className="flip-btn-box fade-anim-box tr-delay04" data-animation="fade-anim">
                                            <a href="#" className="flip-btn pointer-large" data-text="email@xen_agency.com">email@xen_agency.com</a>
                                        </h6>
                                    </p> */}
                                </div>
                            </div>

                            <div className="contact-box-column bottom-padding-60">
                                <div data-animation-container className="content-left-margin-20">
                                    <p data-animation-child className="small-title-oswald red-color overlay-anim-box2" data-animation="overlay-anim2">Call us (Mon-Fri 9-17)</p>
                                    <p className="title-style text-color-4">
                                        <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01" data-animation="overlay-anim2">Front desk</span><br />
                                    </p>
                                    <h6 data-animation-child className="flip-btn-box fade-anim-box tr-delay04" data-animation="fade-anim">
                                        <a href="#" className="flip-btn pointer-large" data-text="+31 3 20 85 99 35">+31 3 20 85 99 35</a>
                                    </h6>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </section>

            <ContactForm />
        </>
    );
};

export default Contact;
